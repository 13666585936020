import React, { useContext, useState, useEffect } from "react";
import AuthService from "../user/AuthService";
import JwtService from "../user/JwtService";
import { useHistory, useLocation, matchPath } from "react-router";
import { Stack } from "@mui/material";
import { GrayCircularProgress } from "../common/StyledComponents";
import axios from "axios";
import { rule5Env, rule5properties } from "../properties";
import { usePostHog } from "posthog-js/react";

const UserContext = React.createContext();
const UserUpdateContext = React.createContext();
const UserReloadContext = React.createContext();

export function useUser() {
  return useContext(UserContext);
}

export function useUserUpdate() {
  return useContext(UserUpdateContext);
}

export function useUserReload() {
  return useContext(UserReloadContext);
}

export const SALESFORCE_CRM_SYSTEM = "Salesforce";
export const COREAPP_USER_ROLES = {
  orgAdmin: "Organization Admin",
  salesRep: "Sales Rep",
  contentUser: "Content User",
  // monitorUser: "Monitor User", // TODO
};
export const ALLOWED_ACTIONS = {
  viewResearch: "View Research",
  viewPeopleConnections: "View People Connections",
  requestResearch: "Request Research",
  viewNotifications: "View Notifications",
  deleteNotifications: "Delete Notifications",
  emailSupport: "Email Support",
  shareOpportunities: "Share Opportunities",
  inviteYourTeam: "Invite your Team",
  conversations: "Conversations",
  promptBooks: "Prompt Books",
  content: "Content",
  monitor: "Monitor",
  personalizeCampaign: "Personalize Campaign",
};
export const PLAN_TYPES = {
  basicMonitor: "Basic - Monitor",
  basic: "Basic",
  professional: "Professional",
  professionalPlus: "Professional Plus",
  standard: "Standard",
  premium: "Premium",
  premiumContent: "Premium - Content",
};

const DEFAULT_USER = {
  firstName: "",
  lastName: "",
  email: "",
  role: "",
  userSettingInfo: {
    profilePicture: null,
  },
  userSubscription: {
    allowedActions: [],
  },
  profile: {},
};

export default function UserContextProvider({ children }) {
  const posthog = usePostHog();
  const [currentUser, setCurrentUser] = useState(DEFAULT_USER);
  const [isApplicationReady, setIsApplicationReady] = React.useState(false);
  const location = useLocation();
  const history = useHistory();

  async function loadUserAndProfile() {
    const refreshToken = await AuthService.refreshToken();
    if (refreshToken.accessToken) {
      JwtService.setToken(refreshToken.accessToken, refreshToken.expiresIn);
      setUser(refreshToken);
      setTimeout(() => {
        setIsApplicationReady(true);
      }, 700);
    } else {
      if (
        matchPath(location.pathname, {
          path: ["/main", "/det"],
          exact: false,
          strict: true,
        })
      ) {
        history.push(location.pathname + location.search);
      }
      setTimeout(() => {
        setIsApplicationReady(true);
      }, 700);
    }
  }

  useEffect(() => {
    loadUserAndProfile();
  }, []);

  async function setUser(user) {
    if (user) {
      // Fetch profile info to add to user object.
      const userProfile = await axios.get(rule5properties.userProfileApiUrl);
      const userToSet = { ...currentUser, ...user };
      if (userProfile.data) {
        userToSet.profile = userProfile.data;
      }
      // Idenfity with posthog.
      posthog.identify(userToSet.email, {
        email: userToSet.email,
        orgName: userToSet.profile?.orgName,
        name: `${userToSet.firstName} ${userToSet.lastName}`,
        role: userToSet.role,
        planType: userToSet.userSubscription?.planType,
        environment: rule5Env,
      });
      setCurrentUser(userToSet);
    } else {
      // Clear the user from context.
      setCurrentUser(DEFAULT_USER);
      // Reset posthog.
      posthog.reset();
    }
  }

  return (
    <UserContext.Provider value={currentUser}>
      <UserUpdateContext.Provider value={setUser}>
        <UserReloadContext.Provider value={loadUserAndProfile}>
          {isApplicationReady ? (
            children
          ) : (
            <Stack
              style={{ position: "absolute", width: "100%", height: "100%" }}
              alignItems="center"
              justifyContent="center"
            >
              <GrayCircularProgress size={40} />
            </Stack>
          )}
        </UserReloadContext.Provider>
      </UserUpdateContext.Provider>
    </UserContext.Provider>
  );
}

export { UserContext };
