import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { rule5properties } from "../properties";
const PREFERENCES_QUERY_KEY = ["gptPreferences"];

export function useGptPreferences() {
  return useQuery({
    queryKey: PREFERENCES_QUERY_KEY,
    queryFn: async () => {
      const { data } = await axios.get(rule5properties.gptPreferences);
      return data;
    },
  });
}

export function useUpdateGptPreferences() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (preferences) => {
      const { data } = await axios.patch(
        rule5properties.gptPreferences,
        preferences
      );
      return preferences;
    },
    onSuccess: (data) => {
      // Update the cache with the new data
      queryClient.setQueryData(PREFERENCES_QUERY_KEY, data);
    },
  });
}
