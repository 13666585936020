import React from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  ClickAwayListener,
} from "@mui/material";
import {
  PopperComponent,
  StyledPopper,
  StyledTextfield,
} from "../../common/StyledComponents";
import { isEmpty, isEqual } from "lodash";
import { useDialog } from "../../context/DialogContext";
import UploadContent from "../../modal/UploadContent";
import axios from "axios";
import { rule5properties } from "../../properties";
import useSnack from "../../context/Snack";
import { isSuccessStatus } from "../../common/RequestUtils";
import { CloseBox } from "./CardTypesChip";

const ContentsChip = React.memo(function ContentsChip(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [contents, setContents] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const snackBar = useSnack();

  const { selectedContents, setSelectedContents, disabled } = props;

  const dialog = useDialog();

  const label = `Considering ${selectedContents?.length} piece${
    selectedContents?.length === 1 ? "" : "s"
  } of content`;

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (open) {
      setLoading(true);
      axios
        .get(rule5properties.contents)
        .then((response) => {
          setLoading(false);
          if (isSuccessStatus(response.status)) {
            if (!response.data || response.data?.length === 0) {
              dialog.openModal("Upload Content", UploadContent, {}, "sm");
              handleClose();
            } else {
              setContents(
                sortByMovingSelectedIds(response.data, selectedContents)
              );
            }
          } else {
            snackBar.createSnack("Unexpected error loading contents.");
          }
        })
        .catch(function (error) {
          setLoading(false);
          snackBar.createSnack("Unexpected error loading contents.");
          if (error.response) {
            console.log(error.response.status);
            console.log(error.response.data);
          }
        });
    }
  }, [open]);

  return (
    <>
      <Chip
        size="small"
        label={label}
        variant="outlined"
        onClick={handleClick}
        sx={{
          fontSize: "12px",
          height: "24px",
          width: "fit-content",
          borderColor: "#b0c7da",
          color: "#525252",
        }}
      />
      <StyledPopper
        anchorEl={anchorEl}
        open={open}
        id={"Contents select"}
        placement="top-end"
        sx={{ width: "450px" }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "750px",
              width: "100%",
            }}
          >
            <Autocomplete
              onChange={(event, newSelectedContents) => {
                setSelectedContents(newSelectedContents);
              }}
              disabled={disabled}
              disableClearable={disabled}
              getOptionDisabled={() => disabled}
              value={selectedContents}
              loading={loading}
              multiple
              PopperComponent={PopperComponent}
              selectOnFocus
              open
              clearIcon={false}
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={contents}
              popupIcon={""} // Remove the little dropdown arrow
              isOptionEqualToValue={(option, value) => {
                return option.contentId === parseInt(value.contentId);
              }}
              getOptionLabel={(option) => option.name}
              noOptionsText="No contents found."
              onClose={(event, reason) => {
                if (reason === "escape") {
                  handleClose();
                }
              }}
              renderTags={() => {}}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {option.name}
                </li>
              )}
              sx={{ width: "450px" }}
              renderInput={(params) => {
                const { InputLabelProps, ...rest } = params;
                const InputProps = {
                  ...params.InputProps,
                };
                return (
                  <>
                    <CloseBox handleClose={handleClose} />
                    <StyledTextfield
                      autoFocus
                      placeholder={"Search contents…"}
                      {...rest}
                      {...InputProps}
                      {...params}
                    />
                    <div
                      style={{
                        display: "flex",
                        borderBottom: "1px solid gainsboro",
                        justifyContent: "space-between",
                        padding: "5px 10px",
                      }}
                    >
                      <Button
                        disableRipple
                        variant="flat"
                        size="small"
                        disabled={disabled || isEmpty(selectedContents)}
                        color="rgb(107,149,185)"
                        sx={{ color: "#506f8a" }}
                        onClick={() => setSelectedContents([])}
                      >
                        Clear all
                      </Button>
                      <Button
                        disableRipple
                        variant="flat"
                        disabled={
                          disabled || isEqual(contents, selectedContents)
                        }
                        size="small"
                        sx={{ color: "#506f8a" }}
                        onClick={() => setSelectedContents(contents)}
                      >
                        Select all
                      </Button>
                    </div>
                  </>
                );
              }}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
});

export default ContentsChip;

function sortByMovingSelectedIds(options, selected, idKey = "contentId") {
  const intersection = options.filter((obj1) => {
    const id = obj1[idKey];
    return selected.some((obj2) => obj2[idKey] === id);
  });

  // Move selected items to the top
  const sortedArray = options.sort((a, b) => {
    const aIsInIntersection = intersection.includes(a);
    const bIsInIntersection = intersection.includes(b);

    if (aIsInIntersection && !bIsInIntersection) {
      return -1;
    } else if (!aIsInIntersection && bIsInIntersection) {
      return 1;
    } else {
      return 0;
    }
  });

  return sortedArray;
}
