import React from "react";
import { makeStyles } from "@mui/styles";
import { Tooltip, Typography } from "@mui/material";
import axios from "axios";
import {
  DataGridPro,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
} from "@mui/x-data-grid-pro";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LabelIcon from "@mui/icons-material/Label";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { handleRowEditStop, renderEditInputCell } from "./PromptBooksTable";
import { rule5properties } from "../../properties";
import LabelAutocomplete from "./LabelAutoComplete";
import LabelChip from "./LabelChip";
import { clipTextWithEllipsis, highlightSubstring } from "../../common/Utils";
import { useDialog } from "../../context/DialogContext";
import GenericConfirmation from "../../modal/GenericConfirmation";
import useSnack from "../../context/Snack";
import { isSuccessStatus } from "../../common/RequestUtils";
import CardTypesChip from "./CardTypesChip";
import ContentsChip from "./ContentsChip";
import { isEmpty } from "lodash";
import PromptInfo from "../prompts/PromptInfo";
import DOMPurify from "dompurify";
const useStyles = makeStyles(() => ({
  root: {
    border: 0,
    "& .MuiDataGrid-main": {
      marginLeft: "30px",
      marginRight: "30px",
    },
    minHeight: "40px",
    "& .MuiDataGrid-columnHeaderTitle": {
      overflow: "hidden",
      lineHeight: "20px",
      whiteSpace: "normal",
      fontSize: "14px",
      fontWeight: "400",
    },
    "& .MuiDataGrid-columnsContainer": {
      minHeight: "100px",
    },
    "& .MuiDataGrid-columnHeaderWrapper": {
      height: "56px",
      color: "#999CA0",
      "& .MuiDataGrid-columnHeader": {
        "& .MuiDataGrid-columnHeaderDraggableContainer": {
          "& .MuiDataGrid-menuIcon": {
            "& .MuiButtonBase-root": {
              color: "#999CA0",
            },
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: "left",
            "& .MuiDataGrid-iconButtonContainer": {
              "& .MuiButtonBase-root": {
                color: "#999CA0",
              },
            },
          },
        },
      },
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-footerContainer": {
      borderTop: "0px",
      "& .MuiTablePagination-root": {
        color: "rgba(0,0,0,0.7)",
        "& .MuiSelect-select": {
          color: "#000000",
          border: "1px solid #F0F0F0",
        },
        "& .MuiSelect-icon": {
          //   color: theme.common.selectIconColor,
        },
        "& .MuiTablePagination-actions": {
          "& .MuiButtonBase-root": {
            "& .MuiSvgIcon-root": {
              color: "rgb(25, 118, 210)",
            },
          },
        },
      },
    },
    "& .MuiDataGrid-cell": {
      cursor: "pointer",
      "&:focus": {
        outline: "none",
      },
      maxHeight: "fit-content !important",
      overflow: "hidden",
      whiteSpace: "initial !important",
      display: "flex !important",
      alignItems: "center !important",
      // paddingTop: "10px !important",
      // paddingBottom: "10px !important",
      fontSize: "1rem",
      borderBottom: "none",
      borderTop: "1px solid gainsboro",
    },
    "& .MuiDataGrid-row": {
      "&:hover": {
        backgroundColor: "rgba(0,0,0,.01)",
      },
      "&:active": {
        backgroundColor: "rgba(0,0,0,.1)",
      },
    },
    "& .MuiGridPanelWrapper-root": {
      opacity: 0.1,
    },
    "& .MuiButton-root.Mui-disabled": {
      color: "rgba(0,0,0,0.5)",
    },
    "& .MuiDataGrid-editInputCell": {
      backgroundColor: "#FCFCFC",
      fontSize: "16px",
      padding: "10px 0px 6px 0px",
      borderRadius: "6px",
      border: "1px solid #DDD",
      margin: "0px 10px",
    },
  },
}));

export default function PromptsTable(props) {
  const { rows, loadPromptBooks, searchValue } = props;

  const [rowModesModel, setRowModesModel] = React.useState({});
  const [labels, setLabels] = React.useState([]);
  const [anchorInfo, setAnchorInfo] = React.useState(null);
  const [values, setValues] = React.useState([]);
  const [pendingValues, setPendingValues] = React.useState([]);
  const open = Boolean(anchorInfo);

  const classes = useStyles();
  const dialog = useDialog();
  const snackBar = useSnack();

  const loadLabels = () => {
    axios.get(rule5properties.labels).then((response) => {
      if (response?.data) {
        setLabels(response?.data);
      }
    });
  };

  const updatedRows = rows.map((row) => {
    row.__reorder__ = clipTextWithEllipsis(row.prompt, 30);
    return row;
  });

  React.useEffect(() => {
    loadLabels();
  }, []);

  const handlePromptDeleteClick = (prompt) => (event) => {
    dialog.openModal(
      "Delete prompt",
      GenericConfirmation,
      {
        children: (
          <>
            <Typography>
              The following prompt will be deleted:<br></br>
              <br></br>
            </Typography>
            <Typography variant="promptBookPrompt">{prompt.prompt}</Typography>
          </>
        ),
        confirmFunction: (callback) => {
          axios
            .delete(`${rule5properties.prompts}/${prompt.promptId}`)
            .then((response) => {
              if (!isSuccessStatus(response.status)) {
                snackBar.createSnack(
                  "Unable to delete prompt: " + response.data.message
                );
              }
              loadPromptBooks();
              if (callback) callback(response);
            });
        },
      },
      "sm"
    );
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const updateRow = (params) => {
    axios
      .patch(rule5properties.prompts, params)
      .then((resp) => {
        if (!isSuccessStatus(resp.status)) {
          snackBar.createSnack("Unable to update prompt: " + resp.data.message);
        }
        loadPromptBooks();
      })
      .catch((error) =>
        snackBar.createSnack("Unexpected error updating prompt.")
      );
  };

  const processRowUpdate = (updatedRow, originalRow) => {
    const params = {
      id: updatedRow.promptId,
      updatePromptObj: {
        prompt: updatedRow.prompt,
      },
    };
    updateRow(params);

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleLabelClick = (event, row) => {
    setPendingValues(row?.labels ? row.labels : []);
    setValues(row?.labels ? row.labels : []);
    setAnchorInfo({ currentTarget: event.currentTarget, promptInfo: row });
  };
  const handleApplyLabel = () => {
    const newValues = pendingValues.filter(
      (obj) => !values.some(({ labelId }) => obj.labelId === labelId)
    );
    if (newValues?.length > 0) {
      savePromptLabels(newValues);
    }
    const deletedValues = values?.filter(
      (obj) => !pendingValues.some(({ labelId }) => obj.labelId === labelId)
    );
    if (deletedValues?.length > 0) {
      deletePromptLabels(deletedValues, anchorInfo?.promptInfo?.promptId);
    }
    if (anchorInfo) {
      anchorInfo.currentTarget?.focus();
    }
    setAnchorInfo(null);
  };

  const savePromptLabels = (newPromptLabels) => {
    const params = {
      promptId: anchorInfo.promptInfo.promptId,
      labels: newPromptLabels.map((newPromptLabel) => {
        if (newPromptLabel?.labelId > 0) {
          return {
            labelId: newPromptLabel.labelId,
          };
        } else if (newPromptLabel?.labelId === -1) {
          // New label creates
          return {
            name: newPromptLabel.name,
            labelInfo: newPromptLabel.labelInfo,
          };
        } else return null;
      }),
    };
    axios.post(rule5properties.promptLabels, params).then((response) => {
      if (response?.data) {
        if (!isSuccessStatus(response.status)) {
          snackBar.createSnack(
            "Unable to update label: " + response.data.message
          );
        }
        loadPromptBooks();
        loadLabels();
      }
    });
  };

  const deletePromptLabels = (deletedValues, promptId) => {
    const params = {
      promptId: promptId,
      labelIds: deletedValues?.map((newLabel) => newLabel.labelId),
    };
    axios
      .delete(rule5properties.promptLabels, { data: params })
      .then((response) => {
        if (!isSuccessStatus(response.status)) {
          snackBar.createSnack(
            "Unable to remove label: " + response.data.message
          );
        }
        loadPromptBooks();
      });
  };

  const columns = React.useMemo(
    () => [
      {
        field: "prompt",
        flex: 2,
        editable: true,
        renderEditCell: renderEditInputCell,
        renderCell: (cellValues) => {
          return (
            <div
              style={{
                display: "flex",
                gap: "8px",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <Typography
                fontSize="15px"
                variant="promptBookPrompt"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    highlightSubstring(cellValues.row.prompt, searchValue)
                  ),
                }}
              ></Typography>
              {cellValues.row.labels?.map((label) => {
                const regexPattern = new RegExp(searchValue, "gi");
                const isHighlighted =
                  searchValue && regexPattern.test(label.name);
                return (
                  <LabelChip
                    key={label.labelId}
                    isHighlighted={isHighlighted}
                    label={label}
                    deletePromptLabels={() => {
                      deletePromptLabels([label], cellValues.row.promptId);
                    }}
                  ></LabelChip>
                );
              })}
            </div>
          );
        },
      },
      {
        field: "cardTypes",
        width: 225,
        editable: false,
        renderCell: (cellValues) => (
          <CardTypesCell
            cellValues={cellValues}
            loadPromptBooks={loadPromptBooks}
          />
        ),
      },
      {
        field: "actions",
        type: "actions",
        width: 160,
        getActions: ({ row, id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (!isInEditMode) {
            return [
              <GridActionsCellItem
                icon={
                  <Tooltip title="Edit prompt">
                    <EditIcon htmlColor="rgb(107,149,185)" />
                  </Tooltip>
                }
                label="Edit"
                onClick={handleEditClick(id)}
              />,
              <GridActionsCellItem
                icon={
                  <Tooltip title="Delete prompt">
                    <DeleteIcon htmlColor="rgb(107,149,185)" />
                  </Tooltip>
                }
                label="Delete"
                onClick={handlePromptDeleteClick(row)}
              />,
              <GridActionsCellItem
                icon={
                  <Tooltip title="Prompt labels">
                    <LabelIcon htmlColor="rgb(107,149,185)" />
                  </Tooltip>
                }
                label="Labels"
                onClick={(event) => {
                  handleLabelClick(event, row);
                }}
              />,
              <GridActionsCellItem
                disableRipple
                icon={<PromptInfo prompt={row} />}
                label="Info"
                sx={{ width: "30px", height: "30px" }}
              />,
            ];
          } else {
            return [
              <GridActionsCellItem
                icon={<DoneIcon />}
                label="Save"
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Add"
                onClick={handleCancelClick(id)}
              />,
            ];
          }
        },
      },
    ],
    [rowModesModel]
  );

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: 5,
      bottom: 5,
    };
  }, []);
  const getRowHeight = React.useCallback(() => "auto", []);

  /** Returns only items which had their index impacted */
  function resequenceIndexes(id, oldIndex, newIndex) {
    const updatedArray = [...updatedRows];
    const itemToMove = updatedArray.find((item) => item.promptId === id);

    updatedArray.splice(oldIndex, 1); // Remove the item from the current position
    updatedArray.splice(newIndex, 0, itemToMove); // Insert the item at the new index

    // Create an array of objects with id and newIndex
    const result = updatedArray.map((item, index) => ({
      promptId: item.promptId,
      sequence: index,
    }));

    // Filter out unimpacted items
    return result.filter(
      (item) =>
        item.sequence >= Math.min(oldIndex, newIndex) &&
        item.sequence <= Math.max(newIndex, oldIndex)
    );
  }

  const handleRowOrderChange = async (params) => {
    const updated = resequenceIndexes(
      params.row.promptId,
      params.oldIndex,
      params.targetIndex
    );

    axios
      .patch(rule5properties.promptsSequences, updated)
      .then((resp) => {
        if (!isSuccessStatus(resp.status)) {
          snackBar.createSnack("Unable to update prompt: " + resp.data.message);
        }
        loadPromptBooks();
      })
      .catch((error) =>
        snackBar.createSnack("Unexpected error updating prompt.")
      );
  };

  function dragIcon() {
    return <DragIndicatorIcon htmlColor="#525252" />;
  }

  return (
    <div
      style={{ paddingLeft: "20px", paddingBottom: "10px", minHeight: "10px" }}
    >
      <DataGridPro
        className={classes.root}
        // TODO hacky sx. IIRC the parent datagrid passes down some styles hence important.
        // surely can be fixed
        sx={{
          "& .MuiDataGrid-row": {
            borderTop: "1px solid transparent !important",
          },
          "& .MuiDataGrid-cell": {
            borderTop: "1px solid transparent !important",
          },
          "& .MuiDataGrid-main": {
            marginRight: "0px !important",
          },
        }}
        getRowHeight={getRowHeight}
        rows={updatedRows}
        columns={columns}
        disableSelectionOnClick
        hideFooter
        disableRowSelectionOnClick
        getRowId={(row) => row.promptId}
        getRowSpacing={getRowSpacing}
        rowReordering
        onRowOrderChange={handleRowOrderChange}
        slots={{
          columnHeaders: () => null,
          rowReorderIcon: dragIcon,
        }}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        onRowEditStop={handleRowEditStop}
      />
      {anchorInfo?.promptInfo && (
        <LabelAutocomplete
          labels={labels}
          open={open}
          anchorEl={anchorInfo?.currentTarget}
          loadPromptBooks={loadPromptBooks}
          promptInfo={anchorInfo?.promptInfo}
          handleApplyLabel={handleApplyLabel}
          handleClose={() => {
            setAnchorInfo(null);
          }}
          loadLabels={loadLabels}
          values={values}
          pendingValues={pendingValues}
          setPendingValues={setPendingValues}
        ></LabelAutocomplete>
      )}
    </div>
  );
}

/** So can memo it with hooks.
 * see: https://mui.com/x/react-data-grid/column-definition/#using-hooks-inside-a-renderer */
const CardTypesCell = (props) => {
  const { cellValues, loadPromptBooks } = props;
  const snackBar = useSnack();

  const addContent = (id, params, endpoint) => {
    axios
      .post(`${rule5properties.prompts}/${id}/${endpoint}`, params)
      .then((resp) => {
        if (!isSuccessStatus(resp.status)) {
          snackBar.createSnack("Unable to update prompt: " + resp.data.message);
        }
        loadPromptBooks();
      })
      .catch((error) =>
        snackBar.createSnack("Unexpected error updating prompt.")
      );
  };

  const removeContent = (id, params, endpoint) => {
    axios
      .delete(`${rule5properties.prompts}/${id}/${endpoint}`, {
        data: params,
      })
      .then((resp) => {
        if (!isSuccessStatus(resp.status)) {
          snackBar.createSnack("Unable to update prompt: " + resp.data.message);
        }
        loadPromptBooks();
      })
      .catch((error) =>
        snackBar.createSnack("Unexpected error updating prompt.")
      );
  };

  const updateRow = (params) => {
    axios
      .patch(rule5properties.prompts, params)
      .then((resp) => {
        if (!isSuccessStatus(resp.status)) {
          snackBar.createSnack("Unable to update prompt: " + resp.data.message);
        }
        loadPromptBooks();
      })
      .catch((error) =>
        snackBar.createSnack("Unexpected error updating prompt.")
      );
  };

  const id = cellValues.row?.promptId;
  const selectedCardTypes = React.useMemo(
    () => ({
      types:
        cellValues.row?.cardTypesContext === "Include All" ||
        cellValues.row?.cardTypesContext === "Exclude All"
          ? [] //Backend keeps historical cards in the array and no longer need them if includeAll or excludeAll
          : cellValues.row?.cardTypes?.map((cardType) => cardType.type),
      excludeAll: cellValues.row?.cardTypesContext === "Exclude All",
    }),
    [cellValues.row]
  );

  const setSelectedContents = React.useCallback(
    (selected) => {
      const newSelected = selected.filter(
        (selectedContent) =>
          !cellValues.row?.contents?.some(
            (previous) => selectedContent.contentId === previous.contentId
          )
      );
      if (!isEmpty(newSelected)) {
        addContent(
          id,
          {
            contentIds: newSelected.map((content) => content.contentId),
          },
          "contents"
        );
      }

      const newRemoved = cellValues.row?.contents?.filter(
        (originalContent) =>
          !selected.some(
            (newContent) => originalContent.contentId === newContent.contentId
          )
      );
      if (!isEmpty(newRemoved)) {
        removeContent(
          id,
          {
            contentIds: newRemoved.map((content) => content.contentId),
          },
          "contents"
        );
      }
    },
    [cellValues.row]
  );

  const selectedContents = React.useMemo(
    () => cellValues.row?.contents,
    [cellValues.row]
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
      <CardTypesChip
        setSelectedCardTypes={(selectedObj) => {
          const params = {
            id: id,
            updatePromptObj: {
              cardTypes: selectedObj.types,
              cardTypesContext: selectedObj.excludeAll
                ? "Exclude All"
                : selectedObj.types?.length > 0
                ? "Specified"
                : "Include All",
            },
          };
          updateRow(params);
        }}
        selectedCardTypes={selectedCardTypes}
      />
      <ContentsChip
        setSelectedContents={setSelectedContents}
        selectedContents={selectedContents}
      />
    </div>
  );
};
