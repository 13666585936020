import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  FormControl,
  FormLabel,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import {
  CancelConfirmButtons,
  ErrorBox,
  StyledLabel,
  StyledTextfield,
  GrayCircularProgress,
} from "../../common/StyledComponents";
import { useIntegrationTags } from "../../api/integrations";
import {
  Controller,
  useForm,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import PromptBooksAutocomplete from "../chat/PromptBooksAutocomplete";
import axios from "axios";
import { rule5properties } from "../../properties";
import { isSuccessStatus } from "../../common/RequestUtils";
import {
  DELIVERY_OPTIONS,
  DeliveryOptionLabel,
  RadioOption,
} from "../monitor/common/ConfigureActions";
import { useFunctionalAreas } from "../../api/orgs";
import { useDialog } from "../../context/DialogContext";
import { useCampaigns } from "../../api/campaigns";
import PlatformGroup from "../monitor/common/PlatformGroup";
import { useOutreachConnection } from "../../api/integrations";

export default function CampaignForm(props) {
  const { data: functionalAreas, isLoading } = useFunctionalAreas();

  const defaultFunctionalArea =
    functionalAreas?.length === 1 ? functionalAreas[0] : "";

  const methods = useForm({
    values: { tags: [], functionalArea: defaultFunctionalArea },
  });

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <GrayCircularProgress />
      </Box>
    );
  }
  return (
    <FormProvider {...methods}>
      <CampaignFormContent {...props} />
    </FormProvider>
  );
}

function CampaignFormContent(props) {
  const { refetch: refetchCampaigns } = useCampaigns();
  const { data: functionalAreas } = useFunctionalAreas();
  const {
    data: configuredOutreachConnection,
    isLoading: isLoadingOutreachConnection,
  } = useOutreachConnection();

  const disabled = !configuredOutreachConnection;

  const { control, register, handleSubmit, reset, formState, setValue, watch } =
    useFormContext();

  const salesPlatform = watch("salesPlatform");
  const { data: tags, isLoading: isLoadingTags } = useIntegrationTags({
    enabled: salesPlatform === "Outreach",
  });

  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const [promptBooksAnchorEl, setPromptBooksAnchorEl] = React.useState(null);
  const dialog = useDialog();

  React.useEffect(() => {
    const subscription = watch(() => {
      if (error) setError("");
    });
    return () => subscription.unsubscribe();
  }, [watch, error]);

  const handlePromptBooksClose = () => {
    setPromptBooksAnchorEl(null);
  };

  const handlePromptBooksClick = (event) => {
    setPromptBooksAnchorEl(event.currentTarget);
  };

  const promptBooksOpen = Boolean(promptBooksAnchorEl);

  const onSubmit = (updatedOutreachMappings) => {
    let output = updatedOutreachMappings;
    output.promptId = updatedOutreachMappings.prompt?.id;
    delete output.prompt;
    setLoading(true);

    axios.post(rule5properties.campaigns, output).then((resp) => {
      setLoading(false);
      if (isSuccessStatus(resp.status)) {
        setError("");
        refetchCampaigns();
        dialog.openModal(
          "Personalization agent created",
          CongratsMessage,
          {},
          "md"
        );
      } else if (resp.data.message) {
        setError(resp.data.message);
      }
    });
  };
  if (isLoadingOutreachConnection) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <GrayCircularProgress />
      </Box>
    );
  }

  const prompt = watch("prompt");

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        gap: "20px",
        alignSelf: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Stack
        sx={{
          width: "100%",
          gap: "20px",
          alignSelf: "center",
          maxHeight: "80vh",
        }}
      >
        <PlatformGroup
          configuredOutreachConnection={configuredOutreachConnection}
          name="salesPlatform"
        />
        <div>
          <StyledLabel>Name</StyledLabel>
          <StyledTextfield
            {...register("name")}
            placeholder="Enter name…"
            sx={{ width: "100%" }}
            disabled={disabled}
          />
        </div>
        <Controller
          render={({ field }) => (
            <Autocomplete
              {...field}
              disabled={disabled}
              autoComplete
              autoHighlight
              openOnFocus
              blurOnSelect
              includeInputInList
              onChange={(e, data) => field.onChange(data)}
              noOptionsText={"No options found."}
              ListboxProps={{ style: { maxHeight: 350 } }}
              options={functionalAreas?.sort().map((option) => option)}
              renderInput={(params) => {
                const { InputLabelProps, InputProps, ...rest } = params;
                return (
                  <React.Fragment>
                    <StyledLabel>Functional Area</StyledLabel>
                    <StyledTextfield
                      placeholder="Select functional area…"
                      {...params.InputProps}
                      {...rest}
                    />
                  </React.Fragment>
                );
              }}
            />
          )}
          name="functionalArea"
          control={control}
        />
        <Controller
          render={({ field }) => (
            <Autocomplete
              {...field}
              disabled={disabled}
              onChange={(e, data) => {
                field.onChange(data);
              }}
              multiple
              loading={isLoadingTags}
              id="tags-standard"
              options={tags || []}
              renderInput={(params) => {
                const { InputLabelProps, InputProps, ...rest } = params;
                return (
                  <React.Fragment>
                    <StyledLabel style={{ textAlign: "left" }}>
                      Prospect lists
                    </StyledLabel>
                    <StyledTextfield
                      {...params.InputProps}
                      {...rest}
                      placeholder="Select tags…"
                    />
                  </React.Fragment>
                );
              }}
            />
          )}
          name={`tags`}
          type="select"
          control={control}
        />
        <div>
          <StyledLabel>Personalization prompt</StyledLabel>
          <StyledTextfield
            sx={{
              "& .MuiInputBase-input": {
                cursor: "pointer",
                maxHeight: "30vh",
                overflowY: "auto !important",
              },
              width: "100%",
              overflowY: "hidden",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
            onClick={!disabled ? handlePromptBooksClick : undefined}
            placeholder="Select prompt…"
            multiline
            value={prompt ? prompt.prompt : ""}
            disabled={disabled}
            InputProps={{
              readOnly: true,
              style: { cursor: disabled ? "default" : "pointer" },
            }}
          />
        </div>
        <PromptBooksAutocomplete
          open={promptBooksOpen}
          anchorEl={promptBooksAnchorEl}
          setValue={(newValue) => {
            setValue("prompt", newValue);
          }}
          handleClose={handlePromptBooksClose}
        />
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">
            Delivery options
          </FormLabel>
          <Controller
            name="deliveryOption"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <RadioGroup
                {...field}
                disabled={disabled}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                {DELIVERY_OPTIONS.map((option) => (
                  <RadioOption
                    value={option.code}
                    text={<DeliveryOptionLabel option={option} />}
                    key={option.code}
                    disabled={disabled}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
        <Stack sx={{ width: "100%", gap: "20px", pb: 3 }} alignItems="center">
          <Collapse in={formState.isDirty}>
            <CancelConfirmButtons
              loading={loading}
              sx={{ mt: 1, width: "360px" }}
              cancelText="Cancel"
              cancelFunction={() => {
                reset();
                dialog.closeModal();
              }}
              confirmText="Create"
              confirmDisabled={formState.errors?.length > 0}
            />
          </Collapse>
          {error && <ErrorBox sx={{ width: "100%" }}>{error}</ErrorBox>}
        </Stack>
      </Stack>
    </form>
  );
}

function CongratsMessage(props) {
  const dialog = useDialog();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "30px",
      }}
      key="final-text"
    >
      <Typography>
        🎉 Your personalization agent has been successfully created! 🚀 Your
        request will run in the background. It may take several minutes -
        typically 1 to 10 minutes. You're all set to make a stronger impact with
        tailored messaging!
      </Typography>
      <Box sx={{ display: "flex", gap: "10px", alignSelf: "end" }}>
        <Button variant="contained" onClick={() => dialog.closeModal()}>
          Continue
        </Button>
      </Box>
    </Box>
  );
}
