import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { rule5properties } from "../properties";

const fetchCampaigns = async () => {
  const response = await axios.get(rule5properties.campaigns);
  return response.data;
};

export const useCampaigns = () => {
  return useQuery("campaigns", fetchCampaigns, {
    staleTime: 60000, // Consider data fresh for 1 minute
    placeholderData: [],
  });
};

export const useCampaignResults = (campaignId) => {
  return useQuery(
    ["campaignResults", campaignId],
    async () => {
      try {
        const url = new URL(rule5properties.campaignResults);

        if (campaignId != null) {
          url.searchParams.append("campaignId", campaignId);
        }

        const campaignResultsResponse = await axios.get(url.toString());

        if (campaignResultsResponse.data) {
          return campaignResultsResponse.data;
        }
        throw new Error("No results found");
      } catch (error) {
        console.error("Error loading results:", error);
        throw error;
      }
    },
    {
      staleTime: 60000 * 5, // Consider data fresh for 5 minutes);
      refetchOnMount: false,
    }
  );
};

export const useCampaignErrors = (campaignId) => {
  return useQuery(
    ["campaignErrors", campaignId],
    async () => {
      try {
        const campaignErrorsResponse = await axios.get(
          `${rule5properties.campaignErrors}`,
          {
            params: { campaignId },
          }
        );
        if (campaignErrorsResponse.data) {
          return campaignErrorsResponse.data;
        }
        throw new Error("No errors found");
      } catch (error) {
        console.error("Error loading errors:", error);
        throw error;
      }
    },
    {
      enabled: !!campaignId,
      staleTime: 60000 * 5, // Consider data fresh for 5 minutes
      refetchOnMount: false,
    }
  );
};

export const useDeleteCampaign = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (campaignId) => {
      const response = await axios.delete(
        `${rule5properties.campaigns}/${campaignId}`
      );
      return response;
    },
    {
      onSuccess: () => {
        // Invalidate and refetch the campaigns list after deletion
        queryClient.invalidateQueries("campaigns");
      },
    }
  );
};

export const usePatchCampaign = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ campaignId, updates }) => {
      const response = await axios.patch(
        `${rule5properties.campaigns}/${campaignId}`,
        updates
      );
      return response.data;
    },
    {
      onSuccess: (updatedCampaign) => {
        // Update the specific campaign in the campaigns list
        queryClient.setQueryData("campaigns", (oldCampaigns) => {
          return oldCampaigns?.map((campaign) =>
            campaign.campaignId === updatedCampaign.campaignId
              ? updatedCampaign
              : campaign
          );
        });
      },
    }
  );
};
