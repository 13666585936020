import axios from "axios";
import { rule5properties } from "../properties";
import { useQuery } from "react-query";

export const usePromptCompletion = (promptCompletionId) => {
  return useQuery({
    queryKey: ["promptCompletion", promptCompletionId],
    queryFn: async () => {
      const { data } = await axios.get(
        `${rule5properties.promptCompletions}/${promptCompletionId}`
      );
      return data;
    },
    enabled: !!promptCompletionId,
  });
};
