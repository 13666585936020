import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { scrollbarStyleThin } from "../common/StyledComponents";
import PreviousNextPicker from "../common/PreviousNextPicker";
import DOMPurify from "dompurify";
import { useCampaignErrors } from "../api/campaigns";

export default function ViewCampaignErrors(props) {
  const { campaignError, setSelectedResult } = props;
  const { data: campaignErrors } = useCampaignErrors(campaignError.campaignId);

  if (!campaignError || !campaignErrors) {
    return null;
  }

  const displayedIndex = campaignErrors?.findIndex(
    (c) => c.campaignErrorId === campaignError.campaignErrorId
  );

  return (
    <Stack
      sx={{ height: "auto", overflow: "hidden" }}
      flexDirection="column"
      alignItems="start"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Stack>
          <Typography variant="modalHeader1">{"Error message: "}</Typography>
          <Typography variant="body1" color="error">
            {campaignError.errorMessage}
          </Typography>
        </Stack>
        <PreviousNextPicker
          mini
          previousTooltip="Previous"
          nextTooltip="Next"
          previousDisabled={displayedIndex === 0}
          nextDisabled={displayedIndex === campaignErrors.length - 1}
          previousClick={() => {
            const newIndex = displayedIndex - 1;
            setSelectedResult(campaignErrors[newIndex], newIndex);
          }}
          nextClick={() => {
            const newIndex = displayedIndex + 1;
            setSelectedResult(campaignErrors[newIndex], newIndex);
          }}
          sx={{ mb: 1, ml: 1 }}
        />
      </Stack>

      <Typography variant="modalHeader1" sx={{ mt: 2 }}>
        {"Personalization details: "}
      </Typography>
      <Typography variant="modalHeader2">
        {"Prospect name: "}
        {campaignError.salesPlatformProspectName}
      </Typography>
      <Typography variant="modalHeader2">
        {"Prospect email: "}
        {campaignError.salesPlatformProspectEmail}
      </Typography>
      <Typography variant="modalHeader2">
        {"Account: "}
        {campaignError.salesPlatformAccountName}
      </Typography>
      <Typography variant="modalHeader2">
        {"Subject: "}
        {campaignError.emailSubject}
      </Typography>
      <Typography variant="modalHeader2">{"Personalized message: "}</Typography>
      <Box
        style={{
          width: "100%",
          backgroundColor: "rgba(0,0,0,.03)",
          margin: "16px 0px 8px 0px",
          padding: "16px 24px",
          borderRadius: "8px",
          overflow: "auto",
          whiteSpace: "pre-wrap",
          maxHeight: "60vh",
        }}
        sx={scrollbarStyleThin}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(campaignError.personalizedMessage),
        }}
      ></Box>
    </Stack>
  );
}
