import React from "react";
import { Box } from "@mui/material";
import { StyledDataGrid } from "../common/StyledComponents";
import { useProspects } from "../api/prospects";
import { CustomToolbar } from "../workspaces/monitor/common/EditAgentList";
import AgentListStatus from "../workspaces/monitor/common/AgentListStatus";

export default function ViewCampaignProspects({ campaignId }) {
  const { data: prospects, isLoading } = useProspects(campaignId);

  const columns = [
    { field: "salesPlatformProspectName", headerName: "Name", flex: 1 },
    { field: "salesPlatformProspectTitle", headerName: "Title", flex: 1 },
    { field: "salesPlatformProspectEmail", headerName: "Email", flex: 1 },
    {
      field: "salesPlatformAccountName",
      headerName: "Account",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: ({ row }) => <AgentListStatus row={row} />,
    },
  ];

  return (
    <Box sx={{ minHeight: "200px", width: "100%" }}>
      <StyledDataGrid
        rows={prospects || []}
        columns={columns}
        loading={isLoading}
        getRowId={(row) => row.campaignProspectId}
        disableSelectionOnClick
        disableRowSelectionOnClick
        autoHeight
        slots={{
          toolbar: CustomToolbar,
        }}
        slotProps={{
          toolbar: { rowCount: prospects?.length },
        }}
        hideFooter={prospects?.length <= 10}
        sx={{
          "& .MuiDataGrid-cell": {
            whiteSpace: "normal",
            lineHeight: "normal",
            padding: "8px",
          },
        }}
      />
    </Box>
  );
}
