import { Box, Stack, Typography, Chip } from "@mui/material";
import React from "react";
import { scrollbarStyleThin } from "../common/StyledComponents";
import { DELIVERY_OPTIONS } from "../workspaces/monitor/common/ConfigureActions";
import { useFormatDateTime } from "../user/useFormatDateTime";
import DOMPurify from "dompurify";
import PreviousNextPicker from "../common/PreviousNextPicker";
import { useCampaigns } from "../api/campaigns";

export default function ViewCampaign(props) {
  const { campaign, setSelectedCampaign } = props;

  const { data: campaigns } = useCampaigns();
  const formatDateTime = useFormatDateTime();

  const deliveryOption = DELIVERY_OPTIONS.find(
    (option) => option.code === campaign.deliveryOption
  );

  const displayedIndex = campaigns.findIndex(
    (c) => c.campaignId === campaign.campaignId
  );

  let lastRunTimeText;
  if (!campaign.lastRunTime) {
    lastRunTimeText = "Not run yet";
  } else {
    lastRunTimeText = "Last run time: " + formatDateTime(campaign.lastRunTime);
  }
  return (
    <Stack
      sx={{ height: "auto", overflow: "hidden" }}
      flexDirection="column"
      alignItems="center"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Typography variant="modalHeader1">{campaign.campaignName}</Typography>
        <PreviousNextPicker
          mini
          previousTooltip="Previous"
          nextTooltip="Next"
          previousDisabled={displayedIndex === 0}
          nextDisabled={displayedIndex === campaigns.length - 1}
          previousClick={() => {
            const newIndex = displayedIndex - 1;
            setSelectedCampaign(campaigns[newIndex], newIndex);
          }}
          nextClick={() => {
            const newIndex = displayedIndex + 1;
            setSelectedCampaign(campaigns[newIndex], newIndex);
          }}
          sx={{ mb: 1, ml: 1 }}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Typography variant="modalHeader2">
          Sales execution platform: {campaign.salesPlatform}
        </Typography>
        <Typography variant="modalHeader2">{lastRunTimeText}</Typography>
      </Stack>
      {/* New fields */}
      <Stack spacing={1} sx={{ width: "100%", mt: 1 }}>
        <Typography variant="modalHeader2">
          Delivery option: {deliveryOption.message}
        </Typography>
        <Typography variant="modalHeader2">
          Functional area: {campaign.functionalArea}
        </Typography>

        <Stack direction="row" spacing={1}>
          <Typography variant="modalHeader2" sx={{ mb: 1 }}>
            Prospect lists:
          </Typography>
          <Stack direction="row" spacing={1} flexWrap="wrap">
            {campaign.tags.map((tag, index) => (
              <Chip key={index} label={tag} size="small" />
            ))}
          </Stack>
        </Stack>
        <Typography variant="modalHeader2">Personalization prompt:</Typography>
        <Box
          style={{
            width: "100%",
            backgroundColor: "rgba(0,0,0,.03)",
            margin: "16px 0px 8px 0px",
            padding: "16px 24px",
            borderRadius: "8px",
            overflow: "auto",
            whiteSpace: "pre-wrap",
            maxHeight: "30vh",
          }}
          sx={scrollbarStyleThin}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(campaign.prompt),
          }}
        >
          {/* {campaign.prompt} */}
        </Box>
      </Stack>
    </Stack>
  );
}
