import { CircularProgress, Stack, Switch, Tooltip } from "@mui/material";
import React from "react";
import useSnack from "../../context/Snack";

export default function AgentStatusSwitch(props) {
  const { cellValues, onStatusChange } = props;

  const [loading, setLoading] = React.useState(false);
  const [checked, setChecked] = React.useState(
    cellValues.row?.status !== "Disabled"
  );
  const snackBar = useSnack();

  const Icon = () => (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        width: 15,
        height: 15,
        borderRadius: "50%",
        backgroundColor:
          checked && !loading ? "primary.main" : "background.default",
        boxShadow: 1,
      }}
    >
      {loading && <CircularProgress size={12} thickness={6} />}
    </Stack>
  );

  return (
    <Tooltip title={`Status: ${cellValues.row?.status}`}>
      <Switch
        checkedIcon={<Icon />}
        icon={<Icon />}
        checked={checked}
        size="small"
        color="primary"
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
          e.stopPropagation();
          setLoading(true);
          const newStatus = {
            status:
              cellValues.row?.status !== "Disabled" ? "Disabled" : "Active",
          };

          onStatusChange(
            cellValues.row?.agentId || cellValues.row?.campaignId,
            newStatus
          )
            .then(() => {
              setChecked((currChecked) => !currChecked);
            })
            .catch((error) => {
              setLoading(false);
              snackBar.createSnack("Unexpected error updating agent status.");
            })
            .finally(() => {
              setLoading(false);
            });
        }}
      />
    </Tooltip>
  );
}
