import React from "react";
import {
  Box,
  Button,
  Fade,
  Stack,
  Badge,
  Tooltip,
  Typography,
  LinearProgress,
} from "@mui/material";
import Toolbar from "../../common/Toolbar";
import Workspace from "../Workspace";
import { useDialog } from "../../context/DialogContext";
import {
  StyledDataGrid,
  titleButton,
  titleButtonInOpp,
  StyledGridToolbarQuickFilter,
} from "../../common/StyledComponents";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  gridExpandedSortedRowIdsSelector,
  useGridApiContext,
  GridActionsCellItem,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import CampaignResults from "./CampaignResults";
import {
  useCampaigns,
  useDeleteCampaign,
  usePatchCampaign,
} from "../../api/campaigns";
import ViewCampaign from "../../modal/ViewCampaign";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GroupsIcon from "@mui/icons-material/Groups";
import CampaignErrors from "./CampaignErrors";
import CampaignForm from "./CampaignForm";
import { useFormatDateTime } from "../../user/useFormatDateTime";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ViewCampaignProspects from "../../modal/ViewCampaignProspects";
import { isEmpty } from "lodash";
import { formatDate } from "../../common/Utils";
import { DELIVERY_OPTIONS } from "../monitor/common/ConfigureActions";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import GenericConfirmation from "../../modal/GenericConfirmation";
import AgentStatusSwitch from "../monitor/AgentStatusSwitch";

export default function MassOutreach(props) {
  const dialog = useDialog();
  const history = useHistory();
  const location = useLocation();
  const { data: campaigns, isFetched } = useCampaigns();

  const [monitorButtonHover, setMonitorButtonHover] = React.useState(false);
  const [displayedCampaign, setDisplayedCampaign] = React.useState(null);

  // Extract the current detail page from the pathname
  const pathParts = location.pathname.split("/");
  const currentDetailPage = pathParts.length > 3 ? pathParts[3] : "";

  React.useEffect(() => {
    if (isFetched && isEmpty(campaigns)) {
      dialog.openModal("Create personalization agent", CampaignForm, {}, "md");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaigns, isFetched]);

  React.useEffect(() => {
    let campaignId;
    if (location.pathname.split("/").length > 4) {
      campaignId = parseInt(location.pathname.split("/")[4]);
      const campaign = campaigns?.find(
        (campaign) => campaign.campaignId === campaignId
      );
      setDisplayedCampaign(campaign);
    }
  }, [campaigns, location.pathname]);

  return (
    <Box sx={{ height: "100vh" }}>
      <Toolbar>
        <Stack direction="row" alignItems="center">
          <Box
            onClick={() => {
              history.push("/main/personalize-campaigns");
            }}
            sx={currentDetailPage ? titleButtonInOpp : titleButton}
            onMouseEnter={() => {
              setMonitorButtonHover(true);
            }}
            onMouseLeave={() => {
              setMonitorButtonHover(false);
            }}
          >
            Personalization agents
          </Box>
          <Fade
            in={currentDetailPage !== "" && !monitorButtonHover}
            timeout={300}
          >
            <Stack direction="row" justifyContent="space-between">
              <ChevronRight
                sx={{
                  transition: ".2s",
                  m: 1,
                  transform: monitorButtonHover ? "scaleX(-1)" : "none",
                }}
              />
            </Stack>
          </Fade>
          <Fade in={currentDetailPage !== ""} unmountOnExit>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ flexGrow: 1 }}
            >
              <span
                style={{
                  transition: "0.3s",
                  opacity: monitorButtonHover ? 0 : 1,
                }}
              >
                {`${
                  currentDetailPage.charAt(0).toUpperCase() +
                  currentDetailPage.slice(1)
                }${
                  displayedCampaign
                    ? ` for ${displayedCampaign.campaignName}`
                    : ""
                }`}
              </span>
            </Stack>
          </Fade>
        </Stack>
        {currentDetailPage !== "" ? (
          <Button
            startIcon={<ArrowBackIcon />}
            sx={{
              alignSelf: "center",
              mr: 2,
              borderRadius: "8px",
            }}
            disableRipple
            onClick={() => {
              history.push(`/main/personalize-campaigns/`);
            }}
          >
            Return to agents
          </Button>
        ) : (
          <Button
            onClick={() => {
              dialog.openModal(
                "Create personalization agent",
                CampaignForm,
                {},
                "md"
              );
            }}
            variant="contained"
            disableRipple
            disableElevation
            sx={{ alignSelf: "center" }}
          >
            Create personalization agent
          </Button>
        )}
      </Toolbar>
      <Workspace>
        <>
          <Switch>
            <Route
              exact
              path="/main/personalize-campaigns"
              component={CampaignDataGrid}
            />
            <Route
              path="/main/personalize-campaigns/results/:campaignId?"
              component={CampaignResults}
            />
            <Route
              path="/main/personalize-campaigns/errors/:campaignId?"
              component={CampaignErrors}
            />
          </Switch>
        </>
      </Workspace>
    </Box>
  );
}

function CampaignDataGrid(props) {
  const dialog = useDialog();
  const { data: campaigns, isFetching } = useCampaigns();
  const formatDateTime = useFormatDateTime();
  const history = useHistory();
  const deleteCampaign = useDeleteCampaign();
  const patchCampaign = usePatchCampaign();
  const apiRef = useGridApiRef();
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const setSelectedCampaign = (campaign, displayedIndex) => {
    if (Number.isFinite(displayedIndex) && displayedIndex >= 0) {
      apiRef?.current.scrollToIndexes({
        rowIndex: displayedIndex,
        colIndex: 0,
      });
    }
    setRowSelectionModel([campaign.campaignId]);
    dialog.openModal("", ViewCampaign, {
      campaign: campaign,
      setSelectedCampaign: setSelectedCampaign,
    });
  };

  const columns = [
    { field: "campaignName", headerName: "Name", flex: 100 },
    {
      field: "tags",
      headerName: "Prospect list",
      flex: 200,
      valueGetter: (value) => {
        if (!value) {
          return value;
        }
        // Convert the decimal value to a percentage
        return value.value.join(", ");
      },
    },
    {
      field: "deliveryOption",
      headerName: "Delivery option",
      flex: 200,
      valueGetter: (value) => {
        if (!value) {
          return value;
        }
        const deliveryOption = DELIVERY_OPTIONS.find(
          (option) => option.code === value.value
        );
        return deliveryOption ? deliveryOption.message : value.value;
      },
    },
    {
      field: "prompt",
      headerName: "Prompt",
      flex: 400,
    },
    {
      field: "campaignProspectsCount",
      headerName: "Prospects",
      headerAlign: "center",
      align: "center",
      sortable: false,
      flex: 100,
      renderCell: (cellValues) => {
        const count = cellValues.row.campaignProspectsCount;
        const isError = cellValues.row.campaignProspectsErrorCount > 0;
        return (
          <Tooltip title={"View prospects"}>
            <Button
              disableRipple
              onClick={(e) => {
                e.stopPropagation();
                dialog.openModal(
                  `Prospects - ${cellValues.row.campaignName}`,
                  ViewCampaignProspects,
                  {
                    campaignId: cellValues.row.campaignId,
                  }
                );
              }}
              color="inherit"
              sx={{ color: "rgba(0,0,0,.4)", borderRadius: "8px" }}
            >
              <Badge
                badgeContent={count > 0 ? count : null}
                color={isError ? "error" : "primary"}
                overlap="rectangular"
                size="small"
                sx={{ mt: 0.5, mr: count > 0 ? 0.5 : 0 }}
              >
                <GroupsIcon />
              </Badge>
            </Button>
          </Tooltip>
        );
      },
    },
    {
      field: "campaignResults",
      headerName: "Results",
      renderCell: (cellValues) => {
        return (
          <CampaignResultButton
            resultCount={cellValues.row.campaignProspectsCompletedCount}
            campaignId={cellValues.row.campaignId}
            formattedLastRunTime={formatDateTime(cellValues.row.lastRunTime)}
          />
        );
      },
    },
    {
      field: "createdTime",
      headerName: "Created",
      flex: 150,
      valueGetter: (params) => formatDate(new Date(params.value)),
    },
    {
      field: "status",
      headerName: "Active?",
      width: 70,
      renderCell: (cellValues) => {
        return (
          <AgentStatusSwitch
            cellValues={cellValues}
            onStatusChange={async (campaignId, newStatus) => {
              return new Promise((resolve, reject) => {
                patchCampaign.mutate(
                  {
                    campaignId: campaignId,
                    updates: newStatus,
                  },
                  {
                    onSuccess: () => resolve(),
                    onError: (error) => reject(error),
                  }
                );
              });
            }}
          />
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 80,
      align: "right",
      headerAlign: "right",
      getActions: ({ id, row }) => {
        const actions = [];

        if (row.campaignProspectsErrorCount > 0) {
          actions.push(
            <Tooltip title="View errors">
              <GridActionsCellItem
                sx={{ mr: "2px" }}
                icon={
                  <Badge
                    badgeContent={row.campaignProspectsErrorCount}
                    color="error"
                  >
                    <ErrorOutlineOutlinedIcon fontSize="small" />
                  </Badge>
                }
                label="View Errors"
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(`/main/personalize-campaigns/errors/${id}`);
                }}
              />
            </Tooltip>
          );
        }

        actions.push(
          <Tooltip title="Delete">
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={(e) => {
                e.stopPropagation();
                dialog.openModal(
                  "Delete agent",
                  GenericConfirmation,
                  {
                    children: (
                      <Typography variant="body1">
                        The agent, <strong>{row.campaignName}</strong>, will be
                        deleted.
                      </Typography>
                    ),
                    confirmFunction: (callback) => {
                      deleteCampaign.mutate(id, {
                        onSuccess: (resp) => {
                          if (callback) callback(resp);
                        },
                      });
                    },
                  },
                  "sm"
                );
              }}
            />
          </Tooltip>
        );

        return actions;
      },
    },
  ];

  return (
    <StyledDataGrid
      apiRef={apiRef}
      sx={{
        height: "100%",
        mx: "30px",
        "& .MuiDataGrid-cell": {
          alignSelf: "center",
        },
        "& .MuiDataGrid-cellContent": {
          display: "-webkit-box",
          WebkitLineClamp: 4,
          WebkitBoxOrient: "vertical",
          whiteSpace: "pre-wrap",
          alignSelf: "center",
        },
      }}
      onRowClick={({ row }) => {
        setSelectedCampaign(row);
      }}
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      rowSelectionModel={rowSelectionModel}
      getRowHeight={() => "auto"}
      loading={isFetching}
      rows={campaigns}
      columns={columns}
      disableSelectionOnClick
      disableRowSelectionOnClick
      getRowId={(row) => row.campaignId}
      localeText={{
        noRowsLabel: `No campaigns created.`,
      }}
      slots={{
        toolbar: CustomToolbar,
        loadingOverlay: LinearProgress,
      }}
    />
  );
}

function CampaignResultButton(props) {
  const { resultCount, campaignId, formattedLastRunTime } = props;
  const history = useHistory();

  return (
    <Tooltip
      title={
        formattedLastRunTime
          ? `Last run: ${formattedLastRunTime}`
          : "Not run yet"
      }
    >
      <Button
        {...props}
        disableRipple
        onClick={(e) => {
          e.stopPropagation();
          if (parseInt(resultCount) !== 0) {
            history.push(`/main/personalize-campaigns/results/${campaignId}`);
          }
        }}
        sx={{
          ml: -1,
          textTransform: "none",
          fontWeight: 400,
          textDecoration: resultCount > 0 && "underline",
          color: resultCount <= 0 ? "rgba(0,0,0,.5)" : undefined,
        }}
      >
        {parseInt(resultCount) > 0 ? resultCount : "No"} result
        {parseInt(resultCount) !== 1 ? "s" : ""}
      </Button>
    </Tooltip>
  );
}

export function CustomToolbar(props) {
  // hmm would be nice if this was globally applied somehow
  const apiRef = useGridApiContext();
  const handleExport = (options) => apiRef.current.exportDataAsCsv(options);

  const buttonBaseProps = {
    color: "primary",
    size: "small",
    startIcon: <SaveAltIcon />,
  };
  return (
    <div style={{ padding: "0px 0px 10px 7px" }}>
      <GridToolbarContainer>
        <Stack direction="row" justifyContent={"space-between"} flexGrow={1}>
          <StyledGridToolbarQuickFilter
            debounceMs={500}
            placeholder="Search…"
          />
          <div>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <Button
              {...buttonBaseProps}
              onClick={() =>
                handleExport({
                  getRowsToExport: getFilteredRows,
                })
              }
            >
              Export
            </Button>
          </div>
        </Stack>
      </GridToolbarContainer>
    </div>
  );
}

const getFilteredRows = ({ apiRef }) =>
  gridExpandedSortedRowIdsSelector(apiRef);
