import { Box, Stack, Button, Tab } from "@mui/material";
import React from "react";
import { scrollbarStyleThin } from "../common/StyledComponents";
import CardTypesChip, {
  getSelectedCardTypesFromConversationPrompt,
} from "../workspaces/chat/CardTypesChip";
import ContentsChip from "../workspaces/chat/ContentsChip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { copyMixedHtml } from "../common/Utils";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { usePromptCompletion } from "../api/promptCompletions";

const PromptBox = ({ content }) => (
  <>
    <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
      <Button
        variant="outlined"
        startIcon={<ContentCopyIcon />}
        onClick={() => copyMixedHtml(content)}
        sx={{ mb: 1 }}
      >
        Copy
      </Button>
    </Box>
    <Box
      style={{
        width: "100%",
        backgroundColor: "rgba(0,0,0,.03)",
        margin: "16px 0px 8px 0px",
        padding: "16px 24px",
        borderRadius: "8px",
        overflow: "auto",
        whiteSpace: "pre-wrap",
        maxHeight: "100%",
      }}
      sx={scrollbarStyleThin}
    >
      {content}
    </Box>
  </>
);

export default function ViewFullPrompt(props) {
  const { promptCompletionId } = props;

  const [tabValue, setTabValue] = React.useState("1");
  const { data: promptCompletion } = usePromptCompletion(promptCompletionId);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getGptPreferences = (contextualData) => {
    if (!contextualData) return "";

    const startMarker = "My information:";
    const endMarker = "Q:";

    const startIndex = contextualData.indexOf(startMarker);
    const endIndex = contextualData.indexOf(endMarker);

    if (startIndex === -1 || endIndex === -1) return "";

    // Extract text between markers and remove leading/trailing whitespace
    return contextualData
      .substring(startIndex + startMarker.length, endIndex)
      .trim();
  };

  return (
    <Stack
      sx={{ height: "calc(93vh - 150px)", overflow: "hidden" }}
      flexDirection="column"
      alignItems="center"
    >
      <div
        style={{
          marginBottom: "15px",
          flexBasis: "100%",
          width: "100%",
          display: "flex",
          gap: "10px",
          justifyContent: "end",
          maxHeight: "fit-content",
        }}
      >
        {/* <Button
          variant="outlined"
          startIcon={<ContentCopyIcon />}
          onClick={() =>
            copyMixedHtml(
              [
                promptCompletion?.prompt?.prompt,
                promptCompletion?.resolvedPrompt?.[0]?.systemPrompt,
                promptCompletion?.resolvedPrompt?.[0]?.promptWithSections,
              ].join("\n\n")
            )
          }
        >
          Copy full prompt
        </Button> */}
        <Stack direction="row" spacing={1}>
          <CardTypesChip
            disabled={true}
            selectedCardTypes={getSelectedCardTypesFromConversationPrompt(
              promptCompletion?.prompt
            )}
          />
          <ContentsChip
            disabled={true}
            selectedContents={promptCompletion?.prompt?.contents}
          />
        </Stack>
      </div>
      <Stack
        spacing={1}
        sx={{
          width: "100%",
          mt: 1,
          height: "95%",
          "& .MuiTabPanel-root": { height: "80%" },
        }}
      >
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleTabChange}
              aria-label="Prompt tabs"
              sx={{ "& .MuiTab-root": { textTransform: "none" } }}
            >
              <Tab label="Full prompt" value="1" />
              <Tab label="System prompt" value="2" />
              <Tab label="User prompt" value="3" />
              <Tab label="GPT preferences" value="4" />
              <Tab label="Contextual data" value="5" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <PromptBox
              content={[
                promptCompletion?.resolvedPrompt?.[0]?.systemPrompt,
                promptCompletion?.resolvedPrompt?.[0]?.promptWithSections,
              ].join("\n\n")}
            />
          </TabPanel>
          <TabPanel value="2">
            <PromptBox
              content={promptCompletion?.resolvedPrompt?.[0]?.systemPrompt}
            />
          </TabPanel>
          <TabPanel value="3">
            <PromptBox content={promptCompletion?.prompt?.prompt} />
          </TabPanel>
          <TabPanel value="4">
            <PromptBox
              content={getGptPreferences(
                promptCompletion?.resolvedPrompt?.[0]?.promptWithSections
              )}
            />
          </TabPanel>
          <TabPanel value="5">
            <PromptBox
              content={
                promptCompletion?.resolvedPrompt?.[0]?.promptWithSections
              }
            />
          </TabPanel>
        </TabContext>
      </Stack>
    </Stack>
  );
}
